import React from 'react';
import { graphql } from 'gatsby';

// import GooglePlay from '../images/googleplay.svg';

import PageLayout from '../components/PageLayout/';
import { Hero } from '../components/Hero/';
import SEO from '../components/seo';
import Section from '../components/Section/';
import { Badges } from '../components/Badges/';
import { ImageTextRow } from '../components/ImageTextRow/';
import NewsletterForm from '../components/NewsletterForm';
import { Container, Title, SubTitle } from '../styles/unsere-vision';

const AboutUs = ({ data: { Hero: HeroImage, Vision1, Vision2 } }) => (
  <PageLayout>
    <SEO title="Unsere Vision" />
    <Hero
      fullscreen
      image={HeroImage}
      title="Unsere Vision"
      subTitle="sozial, uneingeschränkt und nachhaltig.">
      <div className="font-display font-xl tracking-wide">
        Jetzt die DropFriends-App downloaden.
      </div>
      <Badges />
    </Hero>
    <Section fullWidth>
      <ImageTextRow leftImageMobileLast leftImageFluid={Vision1} fullWidth>
        <p className="text-xl md:text-2xl italic">
          "Mit DropFriends verfolgen wir die Vision einer sozialen und leicht zugänglichen
          Logistiklandschaft für Haushalte, Paketboten und Gewerbe - rund um die Uhr und
          nachhaltig."
        </p>
      </ImageTextRow>
      <ImageTextRow rightImageMobileLast rightImageFluid={Vision2} position="right" fullWidth>
        <p className="mb-4">
          Gemeinsam gestalten wir faire Zustellungsbedingungen von Paketsendungen, die nun durch die
          veränderte Kaufkultur der Bevölkerung eine skalierbare Option benötigen.
        </p>
        <p className="mb-4">
          Unsere Mission ist es, die sich im steten Wandel befindlichen Bedürfnisse von Kunden,
          Zustelldiensten und Versorgern digital in Einklang mit bestehenden Infrastrukturen und
          immer geringer werdenden Wohnraumflächen, zu bringen.
        </p>

        <p className="mb-4">
          Wir nehmen die Herausforderung an, mit dem Einsatz von Technologie, dem Bilden einer
          Community und Schaffung eines respektvollen Rahmens, durch eine intuitive, sichere und
          lebensqualitätsteigernde Plattform, eine positive Veränderung zu bewirken.
        </p>

        <p className="">
          So verbindet DropFriends Menschen, um selbstbestimmt, termin- und lieferdienstunabhängig
          die Dinge, die sie lieben, tagesgleich auspacken zu können; mit einem Service, der sich
          uneingeschränkt in die individuellen Alltagspläne, eingliedert.
        </p>
      </ImageTextRow>
    </Section>

    <Container>
      <Title>Jetzt die DropFriends-App downloaden.</Title>

      <SubTitle>Erhältlich im App Store und im Google Play Store.</SubTitle>

      <Badges className="my-4" />

      <NewsletterForm />
    </Container>
  </PageLayout>
);

export const query = graphql`
  {
    Hero: file(relativePath: { eq: "vision.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Vision1: file(relativePath: { eq: "vision-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
    Vision2: file(relativePath: { eq: "vision-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`;
export default AboutUs;
